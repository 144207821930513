import * as React from "react";
import { kebabCase } from "lodash";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout/layout";
import * as styles from "./tag.module.css";
import "@fontsource/lato";
import Seo from "../components/Seo/seo";

const TagsPage = ({
  data: {
    allMarkdownRemark: { group },
  },
}) => (
  <Layout>
    <Seo title="Tags" />
    <div style={{ marginBottom: "6rem" }}>
      <h1 className={styles.tagHeader}>Tags</h1>
      <div className={styles.taglist}>
        {group.map((tag) => (
          <div className={styles.tagLink} key={tag.fieldValue}>
            <Link to={`/tags/${kebabCase(tag.fieldValue)}/`}>
              {tag.fieldValue} ({tag.totalCount})
            </Link>
          </div>
        ))}
      </div>
    </div>
  </Layout>
);

export default TagsPage;

export const tagPageQuery = graphql`
  query TagsQuery {
    allMarkdownRemark(
      filter: { frontmatter: { language: { eq: "en" } } }
      limit: 1000
    ) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`;
